import React from 'react';
import '../components/Footer.css';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
const Footer = () => {
    const { t } = useTranslation();
    let className = '';
    if (i18n.language == 'en_si') {
        className += ' hidden';
    }
    var lang = i18n.language;
    return (
        <>
            <div className="container-fluid footer">
                <div className="container">
                    <div className="row logo">
                        <div className="col">
                            <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="nGen logo" height="50px;" />
                        </div>
                    </div>
                    <div className="row fmenu">
                        <ul className="footer-menu">
                            <li><NavLink to={t('url.home')} className="footer-link" activeClassName="active" exact>{t('pages.home')}</NavLink></li><gdot />
                            <li><NavLink to={t('url.whoweare')} className="footer-link" activeClassName="active" exact>{t('pages.whoweare')}</NavLink></li><gdot />
                            <li><NavLink to={t('url.whatwedo')} className="footer-link" activeClassName="active" exact>{t('pages.whatwedo')}</NavLink></li><gdot />
                            <li><NavLink to={t('url.products')} className="footer-link" activeClassName="active" exact>{t('pages.products')}</NavLink></li><gdot />
                            <li><NavLink to={t('url.contact')} className="footer-link" activeClassName="active" exact>{t('pages.contact')}</NavLink></li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {lang.includes('_si') && (
                                <p>
                                    <strong>NGEN, energetske rešitve d.o.o.</strong><br />
                                    <strong>{t('footer.text-1')}:</strong> Moste 101, 4274 Žirovnica, Slovenija<br />
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+38645812020">+386 (0)4 581 20 20</a><br />
                                    <strong>{t('footer.text-10')}:</strong> SI24576239<br />
                                    <strong>{t('footer.text-3')}:</strong> info@ngen.si
                                </p>
                            )}                           
                            {lang.includes('_hr') && (
                                <p>
                                    <strong>NGEN Smart Energy Systems d.o.o.</strong><br />
                                    <strong>{t('footer.text-1')}:</strong> Avenija Većeslava Holjevca 40, Zagreb 10000, Hrvatska<br />                                  
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+38512111889">+385 1 2111 889</a><br />
                                    <strong>{t('footer.text-10')}:</strong> HR58540789123<br />
                                    <strong>{t('footer.text-3')}:</strong> info@ngen.hr
                                </p>
                            )}
                            {lang.includes('_pl') && (
                                <p>
                                    <strong>NGEN Polska Sp. z.o.o.</strong><br />
                                    <strong>{t('footer.text-1')}:</strong> ul. Grójecka 34 lok. 8, 02-308 Warszawa<br />                                    
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+38645812020">+386 (0)4 581 2020</a><br />
                                    <strong>{t('footer.text-10')}:</strong> PL7011138119<br />
                                    <strong>{t('footer.text-3')}:</strong> info@ngen-energy.pl
                                </p>
                            )}
                            {lang.includes('_pt') && (
                                <p>
                                    <strong>NGEN Smart Grids LDA & NGEN Smart Trading D.o.o.</strong><br />
                                    <strong>{t('footer.text-1')}:</strong> Praça Nuno Rodrigues dos Santos 7, 1600-171 Lisboa, Portugal<br />                                    
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+351215864038">+351 215 864 038</a><br />
                                    <strong>{t('footer.text-10')}:</strong> PT517316536<br />
                                    <strong>{t('footer.text-3')}:</strong> info@ngen.pt
                                </p>
                            )}
                            {lang.includes('_at') && (
                                <p>
                                    <strong>NGEN GmbH</strong><br />
                                    Villacher Ring 59, 9020 Klagenfurt am Wörthersee, {t('footer.text-9')}<br />
                                    <strong>{t('footer.text-7')}:</strong> Moste 101, 4274 Žirovnica, {t('footer.text-8')}<br />
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+43463207040">+43 (0) 463 20 70 40</a><br />
                                    <strong>{t('footer.text-10')}:</strong> ATU79437913<br />
                                    <strong>{t('footer.text-3')}:</strong> office@ngen.at
                                </p>
                            )}
                            {lang.includes('_de') && (
                                <p>
                                    <strong>NGEN Germany GmbH</strong><br />
                                    Stehnweg 2, 63500 Seligenstadt, {t('footer.text-9')}<br />                                    
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+491736802402">+49 (0) 173 680 24 02</a><br />
                                    <strong>{t('footer.text-10')}:</strong> DE370073848<br />
                                    <strong>{t('footer.text-3')}:</strong> <a href="mailto:office@ngen.de">office@ngen.de</a>
                                </p>
                            )}
                            {lang.includes('_es') && (
                                <p>
                                    <strong>NGEN, energetske rešitve d.o.o.</strong><br />
                                    <strong>{t('footer.text-1')}:</strong> Moste 101, 4274, Žirovnica, Slovenija<br />
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+38645812020">+386 (0)4 581 2020</a><br />
                                    <strong>{t('footer.text-3')}:</strong> info@ngen.si
                                </p>
                            )}
                            {lang.includes('_gb') && (
                                <p>
                                    <strong>NGEN LIMITED</strong><br />
                                    <strong>{t('footer.text-1')}:</strong> 167-169 Great Portland Street, London, England, W1W 5PF<br />
                                    <strong>{t('footer.text-2')}:</strong> <a href="tel:+443300243738">+44 330 024 37 38</a><br />
                                    <strong>{t('footer.text-3')}:</strong> info@ngen.co.uk
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {lang.includes('_si') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                            {lang.includes('_hr') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                            {lang.includes('_pt') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                            {lang.includes('_pl') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                            {lang.includes('_at') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                            {lang.includes('_de') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                            {lang.includes('_es') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                            {lang.includes('_gb') && (
                                <a href="https://www.linkedin.com/company/ngen-d-o-o/" target="_blank" rel="noopener noreferrer" className="twitterlink"><i className="fa-brands fa-linkedin"></i></a>
                            )}
                        </div>
                    </div>
                    <div className="row fmenu">
                        {lang.includes('_si') && (
                            <ul className="footer-menu">
                                <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.customeraccess')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.customeraccess')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.support')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.support')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li><gdot className={className} />
                                <li className={className}><NavLink to={t('url.documents')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.documents')}</NavLink></li><gdot />
                                <li><NavLink to='https://zvizgastvo.ngen-group.eu' className="footer-link-bottom" activeClassName="active" exact>Žvižgaštvo</NavLink></li>
                            </ul>
                        )}
                        {lang.includes('_hr') && (
                            <ul className="footer-menu">
                                {/* <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.customeraccess')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.customeraccess')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.support')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.support')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li>
                            </ul>
                        )}
                        {lang.includes('_pt') && (
                            <ul className="footer-menu">
                                {/* <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot />
                                {/* <li><NavLink to={t('url.customeraccess')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.customeraccess')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.SupportPortugal')} className="footer-link-bottom" rel="noopener noreferrer">{t('pages.support')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li>
                            </ul>
                        )}
                        {lang.includes('_pl') && (
                            <ul className="footer-menu">
                                {/* <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.customeraccess')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.customeraccess')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.support')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.support')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li>                                                                

                            </ul>
                        )}
                        {lang.includes('_at') && (
                            <ul className="footer-menu">
                                {/* <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />                                
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.impressum')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.impressum')}</NavLink></li><gdot />               
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li>                                
                            </ul>                            
                        )}
                        {lang.includes('_at') && (
                            <ul className="footer-menu">
                            <li><NavLink to={t('url.widerrufsformular')} className="footer-link-bottom" target="_blank" exact>{t('footer.text-6')}</NavLink></li><gdot />
                            <li><a href={t('url.customeraccess')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.customeraccess')}</a></li><gdot />
                            <li><a href={t('url.support')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.support')}</a></li>
                            </ul>
                        )}
                        {lang.includes('_de') && (
                            <ul className="footer-menu">
                                {/* <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot /> 
                                <li><NavLink to={t('url.impressum')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.impressum')}</NavLink></li><gdot />                                                               
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li>                               
                            </ul>
                        )}                        
                        {lang.includes('_es') && (
                            <ul className="footer-menu">
                                {/* <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot /> 
                                <li><NavLink to={t('url.customeraccess')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.customeraccess')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.support')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.support')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li>
                            </ul>
                        )}
                        {lang.includes('_gb') && (
                            <ul className="footer-menu">
                                {/* <li><NavLink to={t('url.legalnotice')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.legalnotice')}</NavLink></li><gdot /> */}
                                <li><NavLink to={t('url.privacypolicy')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.privacypolicy')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.terms')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.terms')}</NavLink></li><gdot /> 
                                <li><NavLink to={t('url.customeraccess')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.customeraccess')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.support')} target="_blank" className="footer-link-bottom" rel="noopener noreferrer">{t('pages.support')}</NavLink></li><gdot />
                                <li><NavLink to={t('url.joinourteam')} className="footer-link-bottom" activeClassName="active" exact>{t('pages.joinourteam')}</NavLink></li>
                            </ul>
                        )} 
                    </div>
                    <div className="row center">
                        <div className="col-md-auto">
                            <p>Portal status: <span className='status-on'>{t('footer.text-4')}</span></p>
                        </div>
                        <div className="col-md-auto">
                            {lang.includes('_si') && (
                                <p>2024 © ngen.si. {t('footer.text-5')}. </p>
                            )}
                            {lang.includes('_hr') && (
                                <p>2024 © ngen.hr. {t('footer.text-5')}. </p>
                            )}
                            {lang.includes('_pt') && (
                                <p>2024 © ngen.pt. {t('footer.text-5')}. </p>
                            )}
                            {lang.includes('_pl') && (
                                <p>2024 © ngen.pl. {t('footer.text-5')}. </p>
                            )}
                            {lang.includes('_at') && (
                                <p>2024 © ngen.at. {t('footer.text-5')}. </p>
                            )}
                            {lang.includes('_de') && (
                                <p>2024 © ngen.de. {t('footer.text-5')}. </p>
                            )}
                            {lang.includes('_es') && (
                                <p>2024 © ngen.es. {t('footer.text-5')}. </p>
                            )}
                            {lang.includes('_gb') && (
                                <p>2024 © ngen.uk. {t('footer.text-5')}. </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer